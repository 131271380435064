import React, {useEffect, useState} from 'react'
import ContactForm from '../../components/blocks/forms/ContactForm'
import Contacts from '../../components/blocks/index/Contacts/Contacts'
import Map from '../../components/blocks/index/Map/Map'
import {sendContactForm} from '../../services/user'
import {Carousel} from 'react-responsive-carousel'
import CustomCarousel from '../../components/ui/Carousel'
import ImagePopup from '../../components/ui/ImagePopup'
import Extender from '../../components/ui/Extender'

import './style.scss'

const MainPage = () => {
  const [isShowImage, setShowImage] = useState(false)
  const [selectedImage, setSelectedImage] = useState('')

  useEffect(() => {
    if (!isShowImage) setSelectedImage('')
  }, [isShowImage])

  const showFullImage = ({image}) => {
    setShowImage(true)
    setSelectedImage(image)
  }

  const onCloseImage = () => {
    setShowImage(false)
  }

  const submitForm = data => sendContactForm(data).then(response => {})

  return (
    <div className="main-page">
      <div className="main-page__wrapper">
        <div className="main-page__top">
          <div className="main-page__top-title">
            <h2>NGFW NeuroFortress</h2>
          </div>
          <div className="main-page__top-desc">
            Межсетевой экран нового поколения, объединяющий в себе высокую производительность, надежность и удобство
            использования, со встроенной системой NGIDS на базе AI для анализа поведения и проактивного реагирования на
            угрозы.
          </div>

          <div className="main-page__carousel">
            <CustomCarousel
              images={[
                {id: 0, src: require('../../images/bg-fw-1.jpg')},
                {id: 1, src: require('../../images/bg-fw-2.jpg')},
                {id: 3, src: require('../../images/bg-fw-1.2.jpg')},
                {id: 4, src: require('../../images/xdr-1.jpg')},
                {id: 5, src: require('../../images/xdr-2.jpg')},
                {id: 6, src: require('../../images/xdr-3.jpg')},
                {id: 7, src: require('../../images/xdr-4.jpg')},
              ]}
            />
          </div>
        </div>

        <section className="main-page__section">
          <div className="main-page__section-wrapper">
            <div className="main-page__title">
              <div className="main-page-cons">
                <div className="main-page-cons__row">
                  <div className="main-page-cons__item">
                    <img width="42px" src={require('../../images/icon-speed.svg')} alt="" />
                    <span>Производительность 40 Гбит/с в режиме IPS</span>
                  </div>
                  <div className="main-page-cons__item">
                    <img width="42px" src={require('../../images/icon-factory.svg')} alt="" />
                    <span>Аппаратная платформа российского производства</span>
                  </div>
                  <div className="main-page-cons__item">
                    <img width="42px" src={require('../../images/icon-network.svg')} alt="" />
                    <span>Централизованная система управления - до 10000 устройств</span>
                  </div>
                  <div className="main-page-cons__item">
                    <img width="42px" src={require('../../images/icon-base.svg')} alt="" />
                    <span>Отказ от использования базы сигнатур</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="main-page__section">
          <div className="main-page__section-wrapper">
            <div className="main-page__title">Ключевые преимущества NGFW NeuroFortress:</div>
            <div className='main-page__desc'>
              <h3>Интегрированная система NGIDS на базе ИИ и машинного обучения</h3>
              Интеллектуальная система обнаружения вторжений нового поколения (NGIDS) анализирует сетевой трафик для
              выявления аномалий и подозрительных активностей. ИИ и машинное обучение обеспечивает высокую точность
              обнаружения и минимальные ложные срабатывания, гарантируя защиту от сложных угроз.
              <br/>
              <br/>
              <h3>Отказ от использования базы сигнатур</h3>
              Отказ от использования традиционной базы сигнатур атак предоставляет значительные преимущества в области
              обнаружения и предотвращения киберугроз. Современные методы поведенческого и эвристического анализа
              обеспечивают более высокую адаптивность, производительность и точность защиты.
              <br/>
              <br/>
              <h3>Аппаратные платформы с архитектурой х86</h3> Для достижения независимости от поставок
              специализированных аппаратных ускорителей NGFW NeuroFortress работает на процессорах общего назначения с
              архитектурой x86. При правильно выбранной архитектуре продукта NGFW NeuroFortress достигает скорости
              межсетевого экранирования до 40 Гбит/с в режиме IPS.
              <br/>
              <br/>
              <h3>Иерархия объектов и правил</h3> Удобная система управления позволяет максимально оптимизировать
              политики безопасности в соответствии с бизнес-процессами компании. При этом увеличение количества
              устройств не усложняет их администрирование и настройку.
              <br/>
              <br/>
              <h3>Выполнена модернизация стека TCP/IP</h3>
              Для высокоскоростной передачи данных был модернизирован стек TCP/IP, путем отказа от стандартного
              сетевого стека Linux, перенесена обработка сетевых пакетов в пользовательское пространство. Эти
              изменения позволяют расширить функциональные возможности без потери производительности.
              <br/>
              <br/>
              <h3>Централизованное управление и отчетность</h3>
              Удобный интерфейс управления и инструменты отчетности для мониторинга и управления безопасностью в
              реальном времени. Полное видение всей сетевой активности и автоматическое обновление политик
              безопасности.
              <br/>
              <br/>
              <h3>Интеграция с продуктами NeuroFortress</h3>
              Тесное взаимодействие с продуктами компании CVA Technologies позволит оперативно реагировать на
              инциденты и минимизировать их влияние на сеть и бизнес.
            </div>
          </div>
        </section>

        <section className="main-page__section">
          <div className="main-page__section-wrapper">
            <div className="main-page__title">Интерфейс NGFW NeuroFortress</div>
            <div className="main-page__section-row">
              <Carousel
                className={`${isShowImage && 'fullscreen-carousel'}`}
                showArrows={true}
                onChange={(i, item) => setSelectedImage(item.props.children.props.src)}
                onClickItem={(i, item) => showFullImage({image: item.props.children.props.src})}>
                <div>
                  <img src={require('../../images/xdr-1.jpg')} />
                </div>

                <div>
                  <img src={require('../../images/xdr-3.jpg')} />
                </div>
                <div>
                  <img src={require('../../images/xdr-7.jpg')} />
                </div>
                <div>
                  <img src={require('../../images/xdr-8.jpg')} />
                </div>
                <div>
                  <img src={require('../../images/xdr-4.jpg')} />
                </div>
                <div>
                  <img src={require('../../images/xdr-5.jpg')} />
                </div>
                <div>
                  <img src={require('../../images/xdr-6.jpg')} />
                </div>
              </Carousel>
            </div>
          </div>
        </section>

        <section className="main-page__section">
          <div className="main-page__section-wrapper">
            <div className="main-page__section-row">
              <div className="systems">
                <div className="systems__list">
                  <div className="systems__system">
                    <Extender
                      title={
                        <div className="systems__system-title">
                          <div className="systems__system-icon NGFW"></div>
                          <p>
                            <b>NGFW</b> (Next-Generation Firewalls) - представляют собой эволюцию традиционных
                            межсетевых экранов (firewalls), предлагая более глубокий уровень защиты и множество
                            дополнительных функций.
                          </p>
                        </div>
                      }>
                      <p className="main-page__desc">
                        NGFW объединяют возможности классических межсетевых экранов с современными технологиями для
                        более эффективного выявления и предотвращения угроз.
                      </p>
                      <p className="main-page__desc">
                        Обширные функции безопасности. NGFW NeuroFortress объединяет в себе функции современных систем
                        безопасности, которые позволяют не только обнаруживать, но и предотвращать вторжения в реальном
                        времени.
                      </p>
                      <p className="main-page__desc">
                        <ul>
                          <li>Обеспечение безопасности на границе сети</li>
                          <li>Модернизированный стек протоколов TCP/IP</li>
                          <li>Иерархическая структура управления политиками безопасности</li>
                        </ul>
                      </p>
                      <p className="main-page__desc">
                        Интеграция подсистемы NGIDS в функционал NGFW обеспечивает всестороннюю защиту сети, объединяя
                        возможности глубокого анализа трафика и проактивного предотвращения угроз.
                      </p>
                    </Extender>
                  </div>
                  <div className="systems__system">
                    <Extender
                      title={
                        <div className="systems__system-title">
                          <div className="systems__system-icon NGWAF"></div>
                          <p>
                            <b>NGWAF</b> (New-Generation Web Application Firewall) - усовершенствованная версия
                            традиционного Web Application Firewall (WAF), которая обеспечивает более глубокую защиту
                            веб-приложений от современных и сложных киберугроз.
                          </p>
                        </div>
                      }>
                      <p className="main-page__desc">
                        Анализ HTTP/HTTPS-запросов: NGWAF анализирует весь входящий и исходящий веб-трафик на уровне
                        HTTP/HTTPS, выявляя вредоносные запросы и аномалии.
                      </p>
                      <p className="main-page__desc">
                        NGWAF обеспечивает защиту от самых современных и сложных киберугроз, включая атаки нулевого дня
                        и APT (Advanced Persistent Threats).
                      </p>
                      <p className="main-page__desc">
                        NGWAF адаптируется к изменяющимся угрозам и поведению пользователей, автоматически обновляя свои
                        правила и политики защиты.
                      </p>
                      <p className="main-page__desc">
                        Защита от вредоносных ботов, которые выполняют автоматические атаки на веб-приложения.
                      </p>
                    </Extender>
                  </div>
                  <div className="systems__system">
                    <Extender
                      title={
                        <div className="systems__system-title">
                          <div className="systems__system-icon NGSIEM"></div>
                          <p>
                            <b>NGSIEM</b> (New-Generation Security Information and Event Management) - это система
                            управления информацией и событиями безопасности, которая объединяет в себе функции
                            традиционных SIEM (Security Information and Event Management) с передовыми технологиями,
                            такими как машинное обучение, искусственный интеллект и автоматизация реагирования на
                            инциденты.
                          </p>
                        </div>
                      }>
                      <p className="main-page__desc">
                        Расширенная корреляция событий, которая собирает и анализирует данные из различных источников,
                        включая сетевые устройства, серверы, приложения, базы данных и облачные сервисы.
                      </p>
                      <p className="main-page__desc">
                        Интеграция с другими системами безопасности, такими как Next-Generation Firewalls (NGFW),
                        Intrusion Prevention Systems (NGIPS), и Extended Detection and Response (XDR), что обеспечивает
                        централизованный контроль и управление.
                      </p>
                      <p className="main-page__desc">
                        Автоматизация и оркестрация процессов безопасности позволяют NGSIEM значительно сократить время
                        реакции на инциденты.
                      </p>
                    </Extender>
                  </div>
                  <div className="systems__system">
                    <Extender
                      title={
                        <div className="systems__system-title">
                          <div className="systems__system-icon NGIDS"></div>
                          <p>
                            <b>NGIDS</b> (New-Generation Intrusion Detection System) - инновационная интеллектуальная
                            система идентификации кибератак на сетевую инфраструктуру, интегрированная в NGFW
                            NeuroFortress, обеспечивающая высокую точность обнаружения атак и минимальные ложные
                            срабатывания.
                          </p>
                        </div>
                      }>
                      <p className="main-page__desc">
                        Анализ трафика на уровне приложений: NGIDS проводит глубокий анализ сетевых пакетов, позволяя
                        выявлять сложные угрозы на уровне приложений.
                      </p>

                      <p className="main-page__desc">
                        <ul>
                          <li>Полная защита от уязвимостей нулевого дня.</li>
                          <li>Обнаружение и предотвращение сложных целевых атак (APT).</li>
                          <li>Автоматический контроль любых сервисов.</li>
                        </ul>
                      </p>
                      <p className="main-page__desc">
                        Интеграция подсистемы NGIDS в функционал NGFW обеспечивает всестороннюю защиту сети, объединяя
                        возможности глубокого анализа трафика и проактивного предотвращения угроз.
                      </p>
                    </Extender>
                  </div>

                  <div className="systems__system">
                    <Extender
                      title={
                        <div className="systems__system-title">
                          <div className="systems__system-icon NGIPS"></div>
                          <p>
                            <b>NGIPS</b> (New-Generation Intrusion Prevention Systems) - усовершенствованная система
                            предотвращения вторжений, внедренная в NGFW NeuroFortress.
                          </p>
                        </div>
                      }>
                      <p className="main-page__desc">
                        Анализ на уровне приложений: NGIPS анализирует содержимое сетевых пакетов на уровне приложений,
                        что позволяет выявлять сложные и целевые атаки
                      </p>
                      <p className="main-page__desc">
                        Обнаружение и блокировка угроз в реальном времени. При обнаружении угрозы NGIPS мгновенно
                        принимает меры по ее блокировке.
                      </p>
                      <p className="main-page__desc">
                        Обеспечение всесторонней защиты сети, включая глубокий анализ трафика и проактивное
                        предотвращение угроз.
                      </p>
                    </Extender>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="main-page__section">
          <div className="main-page__section-wrapper">
            <div className="main-page__title">ДОПОЛНИТЕЛЬНЫЕ ВОЗМОЖНОСТИ:</div>

            <ul className="main-page__list">
              <li>
                <h3>Реализация функционала WAF</h3>
                NGFW NeuroFortress осуществляет защиту веб приложений, выступая в роли маршрутизатора и прокси полезного
                и вредоносного трафика полностью реализуя функционал WAF с тонкой настройкой типов блокируемых запросов,
                оценкой атак и корреляцией событий NGIDS/IPS.
              </li>
              <li>
                {' '}
                <h3>Мониторинг и корреляция событий</h3>
                NGFW NeuroFortress объединяет функции мониторинга событий и расширенного NGIDS для всех серверов
                компании, с хостовым агентом и корреляцией событий для идентификации распределенных хакерских атак
                нового поколения и реагирования на них.
              </li>
              <li>
                <h3>Веб-хранилище файлов</h3> Реализация в функционале NGFW NeuroFortress набора расширений и дополнений
                к протоколу HTTP - WebDAV (Web Distributed Authoring and Versioning) позволяет пользователям создавать,
                изменять и удалять файлы и директории на веб-серверах.
              </li>
              <li>
                <h3>Предоставление веб-сервисов</h3> NGFW имеет возможность размещения на аппаратно-программной платформе
                производительного защищенного веб ресурса на базе nginx, apache для предоставления https доступа с
                защитой WAF, идентификацией и блокировкой DoS/DDoS атак.
              </li>
            </ul>
          </div>
        </section>

        <section className="main-page__section">
          <div className="main-page__section-wrapper">
            <div className="main-page__title">Почему выбирают NGFW NeuroFortress:</div>
            <ul className="main-page__list">
              <li>
                <b>Один из лидеров в инновациях:</b> использование самых современных методов и технологий для
                обеспечения безопасности.
              </li>
              <li>
                <b>Высокая надежность: </b> проверенные решения для защиты сетей любого масштаба.
              </li>
              <li>
                <b>Простота интеграции:</b> легкость внедрения в существующие сетевые инфраструктуры.
              </li>
              <li>
                <b>Постоянная поддержка:</b> высокий уровень поддержки и обслуживания от команды экспертов компании
                CVA Technologies.
              </li>
              <li>
                <b>NGFW NeuroFortress:</b> инновационные технологии, мощь искусственного интеллекта и комплексная защита
                в одном устройстве.
              </li>
            </ul>
          </div>
        </section>

        <section className="main-page__section">
          <img className="img-640" src={require('../../images/fw-front.png')} alt="" />
          <div className="main-page__section-wrapper">
            <div className="main-page__title">Технические характеристики:</div>
            <ul className="main-page__list">
              <li>
                <b>Процессор:</b> Intel Core i7-1355U
              </li>
              <li>
                <b>Память:</b> 32GB DDR5
              </li>
              <li>
                <b>Дисковая система:</b> 1TB NVMe, SATA 4*4K Proxmox
              </li>
              <li>
                <b>Сетевое подключение:</b> 2*10G SFP + оптический, 4х 2,5G Ethernet
              </li>
              <li>
                <b>Интерфейсы:</b> USB Type-C, USB 2.0, USB 3.2 gen1, Display Port 1.4, HDMI 2.0
              </li>
            </ul>
            <div className="main-page__title">Пропускная способность:</div>
            <ul className="main-page__list">
              <li>
                Аппаратно-программный комплекс обеспечивает ввод и обработку для интеллектуальной идентификации и
                блокирования 40 Гбит/с трафика
              </li>
              <li>
                АПК обеспечивает одновременно обрабатываемых сессий/направлений для ИИ идентификации и блокирования до
                40 млн шт.
              </li>
              <li>
                АПК обеспечивает постоянное интеллектуальное наблюдение за одновременно работающими параллельными
                сессиями/направлениями с полезными комплексными данными до 80 тыс шт.
              </li>
            </ul>
          </div>
          <img className="img-640" src={require('../../images/fw-back.png')} alt="" />
        </section>

        <section className="main-page__section">
          <div className="main-page__section-wrapper">
            <div className="main-page__title">Кто мы такие?</div>
            <p className="main-page__desc">
              Представляем вам нашу компанию{' '}
              <a
                className="main-page__link"
                href="https://cvatec.com/index.php/ru/o-nas/cva-technologies"
                target="_blank">
                «CVA Technologies»
              </a>
              , которая является разработчиком программного обеспечения и производителем современных
              электронных систем.
            </p>
            <p className="main-page__desc">
              Нашей компанией была создана система обнаружения и расследования инцидентов угроз безопасности нового
              поколения (NGIDS, Next Generation Intrusion Detection Systems), которая благодаря применению нейронных
              сетей и спецсредств беспроводного контроля является инновационной мерой противодействия кибератакам.
            </p>
            <p className="main-page__desc">
              По данному проекту был одобрен и успешно выполнен государственный Грант Старт-ЦТ. Программное обеспечение
              выполнено в соответствии с ТЗ, испытано и прошло экспертизу Фонда Содействия Инновациям. Получено
              свидетельство регистрации интеллектуальной собственности на программу NGIDS NeuroFortress.
            </p>
            <p className="main-page__desc">
              В рамках развития проекта мы, используя технологию искусственного интеллекта и уже успешно проверенные
              разработки, создали инновационную системы реагирования на киберугрозы, контроль сотрудников и сетевой
              среды, и систему обеспечения безопасности корпоративной инфраструктуры.
            </p>
            <p className="main-page__desc">
              Многолетний опыт работы позволяет решать любые задачи в разработке программного обеспечения. В своих
              разработках мы используем самые новейшие и передовые технологии, что позволит Вам получить совершенно
              новый уровень информационной безопасности.
            </p>
          </div>
        </section>

        <section className="main-page__section">
          <div className="main-page__section-wrapper">
            <div className="main-page__section-row">
              <div className="main-page__section-col">
                <img width="360px" src={require('../../images/image_xdr_1.png')} alt="" />
              </div>
              <div className="main-page__section-col">
                <p className="main-page__desc">
                  Функционал NGFW NeuroFortress интегрирован в один из наших продуктов NGFW NeuroFortress XDR. Данное
                  решение представляет собой объединение технологий кибербезопасности для обеспечения всесторонней и
                  проактивной защиты сетевой инфраструктуры и данных заказчиков.
                </p>
                <a className="main-page__button-link" href="https://neurofortress.ru/" target="_blank">
                  Узнать подробнее
                </a>
              </div>
            </div>
          </div>
        </section>

        <section className="main-page__section">
          <div className="main-page__section-wrapper">
            <div className="main-page__section-row">
              <div className="main-page__section-col">
                <img style={{marginTop: 44}} width="120px" src={require('../../images/minkomsvyaz_0.png')} alt="" />
                <img width="120px" src={require('../../images/fstec.png')} alt="" />
              </div>
              <div className="main-page__section-col">
                <div className="main-page__title">Наши лицензии</div>
                <div className="licence-list">
                  <div className="licence-list__title">РЕЕСТР РОССИЙСКОГО ПРОГРАММНОГО ОБЕСПЕЧЕНИЯ</div>
                  <div className="licence-list__items">
                    <a href="https://reestr.digital.gov.ru/reestr/1765724/" className="licence-list__item">
                      NGFW NEUROFORTRESS XDR № 18928
                    </a>
                    <a href="https://reestr.digital.gov.ru/reestr/1807046/" className="licence-list__item">
                      Менеджер паролей NF MP № 19223
                    </a>
                    <a href="https://reestr.digital.gov.ru/reestr/1765620/" className="licence-list__item">
                      NF Secure Instant Messenger № 18879
                    </a>
                    <a href="https://reestr.digital.gov.ru/reestr/2038723/" className="licence-list__item">
                      NG Web Application Firewall № 20653
                    </a>
                  </div>
                </div>
                <div className="licence-list">
                  <div className="licence-list__title">Лицензии ФСТЭК</div>
                  <div className="licence-list__items">
                    <a
                      href="https://reestr.fstec.ru/regview1?guid=8a685796-6afd-49ba-b99b-23ce5bbf3c88"
                      className="licence-list__item">
                      Л024-00107-77/00651158
                    </a>
                    <a
                      href="https://reestr.fstec.ru/regview2?guid=1a8a110d-c38c-4a22-b5f1-a39a9e4e2678"
                      className="licence-list__item">
                      Л050-00107-77/00651157
                    </a>
                  </div>
                </div>
                <div className="licence-list">
                  <div className="main-page__title">Наши продукты удовлетворяют требованиям ГосСОПКА</div>
                  <p className="main-page__desc">
                    Программное обеспечение компании CVA Technologies может использоваться в следующих информационных
                    системах:
                    <ul>
                      <li>КИИ 1 категории</li>
                      <li>ГИС 1 класса</li>
                      <li>АСУТП 1 класса</li>
                      <li>ИСПДн 1 уровня</li>
                    </ul>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="main-page__bottom">
          <div className="main-page__title">
            Не упустите шанс обеспечить свою безопасность - свяжитесь с нами сегодня!
          </div>
          {/* <a
            className="main-page__bottom-button"
            href="https://cvatec.com/index.php/ru/neurofortress-ru/informatsiya-o-produktakh-neurofortress">
            Заказать
          </a> */}
        </div>
        <section className="main-page__section">
          <div className="main-page__section-wrapper">
            <div className="main-page__title centered">Контактная информация</div>
            <div className="main-page__section-row">
              <div className="main-page__section-col">
                <ContactForm formMethod={submitForm} />
              </div>
              <div className="main-page__section-col">
                <Contacts />
              </div>
            </div>
          </div>
        </section>
        <Map />
      </div>
      {isShowImage && <ImagePopup image={selectedImage} close={onCloseImage} />}
    </div>
  )
}

export default MainPage
